import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { FirebaseContext } from 'context';
import BaseSelect from 'react-select';
import { motion } from 'framer-motion';
import FixRequiredSelect from 'components/Forms/FixRequiredSelect';
import { LoadingSpinner } from 'components';

function FormSelect(props) {
  return <FixRequiredSelect {...props} SelectComponent={BaseSelect} options={props.options} />;
}

function Reports({ events, customDropdownMenuStyles }) {
  const { firebase } = useContext(FirebaseContext);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [generatingLoggedInReport, setGeneratingLoggedInReport] = useState(false);
  const [generatingEventCommentsReport, setGeneratingEventCommentsReport] = useState(false);

  const handleGenerateLoggedInOnTheDayReport = async () => {
    setGeneratingLoggedInReport(true);

    console.log('here', selectedEvent);

    const uidArray = [];

    await firebase.admin.fetchAllFirebaseAuthUserData().then(({ data: allUsers }) => {
      allUsers.forEach((_user) => {
        // Go here to convert the event date and time to a timestamp - https://timestamp.online/
        // and then add three zeros onto the end to make it compatible with the Firebase timestamp. //
        if (new Date(_user.metadata.lastRefreshTime).getTime() >= 1727341200000) {
          uidArray.push(_user.uid);
        }
      });
    });

    const userNamesAndEmailsArray = [];

    const fetchAllFirestoreUserData = async () => {
      const promises = [];

      uidArray.forEach((uid) => {
        promises.push(firebase.admin.getUserFromDatabaseWithUID({ uid }));
      });

      const promiseResults = await Promise.all(promises);

      promiseResults.forEach((res) => {
        if (!res.empty) {
          const { title, name, email, profession, company, eventsUserCanAccess } =
            res.docs[0].data();
          if (eventsUserCanAccess?.length > 0 && eventsUserCanAccess.includes(selectedEvent.eid)) {
            userNamesAndEmailsArray.push({
              title,
              name,
              email,
              profession,
              company
            });
          }
        }
      });
    };

    await fetchAllFirestoreUserData();

    const sortedUsers = userNamesAndEmailsArray.sort((a, b) =>
      a.name.toLowerCase() !== b.name.toLowerCase()
        ? a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : 1
        : 0
    );

    const sortedUsersWithRowNumbers = sortedUsers.map((entry, i) => ({
      rowNumber: i + 1,
      ...entry
    }));

    firebase.userProfile
      .generateLoggedInOnTheDayReport({
        users: sortedUsersWithRowNumbers,
        eventName: selectedEvent.title
      })
      .then(({ data: _data }) => window.open(_data, '_blank') || window.location.replace(_data))
      .catch(console.error)
      .finally(() => setGeneratingLoggedInReport(false));
  };

  const handleGenerateEventCommentsReport = async () => {
    try {
      setGeneratingEventCommentsReport(true);
      const { data } = await firebase.userProfile.generateEventCommentsReport({
        eid: selectedEvent.eid,
        eventName: selectedEvent.title
      });
      console.log(data);
      return window.open(data, '_blank') || window.location.replace(data);
    } catch (error) {
      console.error(error);
    } finally {
      setGeneratingEventCommentsReport(false);
    }
  };

  return (
    <Container>
      <Title>Reports</Title>
      <Card>
        <div>
          <Label>Event</Label>
          <FormSelect
            value={selectedEvent}
            onChange={setSelectedEvent}
            placeholder="Select"
            styles={customDropdownMenuStyles()}
            options={events}
          />
        </div>
        <div>
          <Description>Logged In On Day Report</Description>
          <Button
            type="button"
            disabled={!selectedEvent}
            onClick={() => handleGenerateLoggedInOnTheDayReport()}
            whileHover={selectedEvent && { scale: 1.0125 }}
            whileTap={
              selectedEvent && {
                scale: 0.95
              }
            }>
            {generatingLoggedInReport ? (
              <LoadingSpinner style={{ width: '1.25rem', color: '#fff' }} />
            ) : (
              <span>Generate Report</span>
            )}
          </Button>
        </div>
        <div>
          <Description>Comments Report</Description>
          <Button
            type="button"
            disabled={!selectedEvent}
            onClick={() => handleGenerateEventCommentsReport()}
            whileHover={selectedEvent && { scale: 1.0125 }}
            whileTap={
              selectedEvent && {
                scale: 0.95
              }
            }>
            {generatingEventCommentsReport ? (
              <LoadingSpinner style={{ width: '1.25rem', color: '#fff' }} />
            ) : (
              <span>Generate Report</span>
            )}
          </Button>
        </div>
      </Card>
    </Container>
  );
}

const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  justify-content: center;
  position: relative;
  width: 100%;
`;

const Title = styled.h3`
  color: #004d9a;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75rem;
  text-align: center;
  width: 100%;
`;

const Card = styled.div`
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 6px 20px rgba(196, 196, 196, 0.4);
  display: flex;
  flex-direction: column;
  font-weight: 300;
  gap: 1.75rem;
  max-width: 575px;
  padding: 3.875rem 1.75rem 2.75rem 1.75rem;
  position: relative;
  width: 100%;
`;

const Label = styled.label`
  align-items: center;
  color: #004d9a;
  display: flex;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.125rem;
`;

const Description = styled.h4`
  align-items: center;
  color: #004d9a;
  display: flex;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.625rem;
`;

const Button = styled(motion.button)`
  align-items: center;
  background-color: ${({ disabled }) => (disabled ? '#c4c4c4' : '#004d9a')};
  border-radius: 3rem;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  margin-top: 12px;
  min-width: 240px;
  padding: 1.125rem 2rem;
  text-transform: uppercase;
  transition: background-color 200ms ease-in-out;
  width: max-content;
  :disabled {
    cursor: default;
  }
`;

export default Reports;
